
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment, { Moment } from "moment";
import { WeeklyEvent, MonthlyEvent, DayEvent, BusinessHours } from "@/@types/event";
import CalendarModule from "@/store/calendar";
import FacilityModule from "@/store/facility";
import BirthdaySelect from "@/components/forms/components/BirthdaySelect.vue";
import FacilityService from "@/services/facilities";
import MemberService from "@/services/members";
import { CustomerData, PetData, ReservationData } from "@/@types/reservation";
import { CourseData, CourseDay, CourseDetail, CourseSpecialTime } from "@/@types/course";
import { asyncMap, checkHoliday, deleteNullProp, asyncForEach } from "@/utils/Util";
import _ from "lodash";
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import AllCourseCalendar from "./AllCourseCalendar.vue";
import DayCalendar from "./DayCalendar.vue";

@Component({
  components: {
    FullCalendar,
    BirthdaySelect,
    Button,
    FormSet,
    AllCourseCalendar,
    DayCalendar,
  },
})
export default class Home extends Vue {
  @Ref() fullCalendar!: any;

  private weeklyHeight = 60;

  private get currentEvents() {
    return CalendarModule.currentEvents;
  }

  private get hospitalId() {
    return FacilityModule.hospitalId;
  }

  private get monthlyEvents() {
    return CalendarModule.monthlyEvents;
  }
  private get selectedInitialView() {
    return CalendarModule.selectedInitialView;
  }

  private get weeklyEvents() {
    return CalendarModule.weeklyEvents;
  }
  private get businessHours() {
    return CalendarModule.businessHours;
  }
  private get isReloaded() {
    return CalendarModule.isReloaded;
  }

  private get selectedTargetTime() {
    return CalendarModule.selectedTargetTime;
  }
  private get selectedCourse() {
    return CalendarModule.selectedCourse;
  }

  // private get reservations() {
  //   return CalendarModule.reservations;
  // }

  private reservations: any = [];
  private groupReservations: any = [];

  private get currentDate() {
    return CalendarModule.currentDate;
  }

  private loading = false;

  private loadingUpdateCourse = false;

  private canUpdateCourse = true;

  @Watch("loadingUpdateCourse")
  private handleLoadingUpdateCourse(newVal: boolean) {
    if (newVal) {
      this.canUpdateCourse = false;
    } else {
      setTimeout(() => {
        this.canUpdateCourse = true;
      }, 1000);
    }
  }

  private showChildComponent = false;

  private courses: CourseData[] = [];

  private allCourseReservations: any = [];

  private possibleReservations: any = [];

  // private isEdit = false;

  private selectedReservation: DayEvent = {
    username: "",
    petType: "",
    age: 0,
    genre: "",
    description: "",
    start: "",
    end: "",
  };

  private csvDownloading = false;
  private getAllCourseReservations(data: any) {
    this.allCourseReservations = data;
  }

  private toNowDate() {
    CalendarModule.changeCurrentDate(moment(new Date()).format("YYYY-MM-DD"));
    location.reload();
  }

  private changeCurrentDate(date: Date) {
    const dateMoment = moment(date);
    // 時間を00:00にセット
    dateMoment.set("hour", 0);
    dateMoment.set("minute", 0);
    dateMoment.set("second", 0);
    CalendarModule.changeCurrentDate(moment(dateMoment.toDate()).format("YYYY-MM-DD"));
    window.location.reload();
    if (this.currentDate === "Invalid date") {
      CalendarModule.changeCurrentDate("");
    }
  }

  private async getCourses() {
    try {
      const res = await FacilityService.getCourses(this.hospitalId);
      this.courses = [
        {
          name: "全コース",
          id: "all",
          duration_minute: 30,
          available_count: 1,
          details: [{}],
          facility_id: "all",
          sort_index: 0,
        },
        ...res.data.data,
      ];

      if (!this.selectedCourse && this.courses.length) {
        this.changeCourse(this.courses[0]);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async changeCourse(course: CourseData) {
    CalendarModule.changeSelectedCourse(course);
    if (course.id === "all") {
      CalendarModule.changeSelectedInitialView("timeGridDay");
    }
    if (this.selectedInitialView !== "timeGridDay") {
      this.calendarHeaderDisabled();
      this.businessHoursArrayFactory();
      this.getReservations();
    } else {
      CalendarModule.changeSelectedInitialView("timeGridDay");
    }
    if (this.selectedInitialView === "timeGridWeek") {
      CalendarModule.changeCurrentDate(
        moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format("YYYY-MM-DD")
      );
    }
    location.reload();
  }

  private async getReservations() {
    await this.getPossibleReservations();
    this.calendarHeaderDisabled();
    let reservations: ReservationData[] = [];
    let groupReservations: ReservationData[] = [];
    let groupCourseIds: string[] = [];
    const courseGroupsRes = await FacilityService.getCourseGroups(this.hospitalId);
    const courseGroups = courseGroupsRes.data.data;
    // courseGroups(配列)の中にあるcourse_idsに選択中のコースIDがあればそのコースグループを取得
    const currentCourseGroup = courseGroups.find((courseGroup: any) =>
      courseGroup.course_ids?.includes(this.selectedCourse!.id)
    );
    if (currentCourseGroup) {
      // コースグループの中にあるコースIDから現在のコースIDを除外
      groupCourseIds = currentCourseGroup.course_ids?.filter(
        (courseId: string) => courseId !== this.selectedCourse!.id
      );
    }
    const calendarStartDate = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart);
    const calendarEndDate = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd);
    const startYear = calendarStartDate.get("year").toString();
    const startMonth = String(calendarStartDate.get("month") + 1);
    const endYear = calendarEndDate.get("year").toString();
    const endMonth = String(calendarEndDate.get("month") + 1);
    const res = await FacilityService.getReservations(this.hospitalId, {
      year: startYear,
      month: startMonth,
      course_id: this.selectedCourse!.id,
    });
    reservations = res.data.data;
    await asyncForEach(groupCourseIds, async (courseId: string) => {
      const res = await FacilityService.getReservations(this.hospitalId, {
        year: startYear,
        month: startMonth,
        course_id: courseId,
      });
      groupReservations = [...groupReservations, ...res.data.data];
    });
    if (this.selectedInitialView === "timeGridWeek" || this.selectedInitialView === "dayGridMonth") {
      reservations = reservations.filter(
        (reservation: ReservationData) => reservation.data_type !== "reservation_special"
      );
      groupReservations = groupReservations.filter(
        (reservation: ReservationData) => reservation.data_type !== "reservation_special"
      );
    }
    if (endMonth !== startMonth) {
      const res = await FacilityService.getReservations(this.hospitalId, {
        year: endYear,
        month: endMonth,
        course_id: this.selectedCourse!.id,
      });
      reservations = [...reservations, ...res.data.data];
      await asyncForEach(groupCourseIds, async (courseId: string) => {
        const res = await FacilityService.getReservations(this.hospitalId, {
          year: endYear,
          month: endMonth,
          course_id: courseId,
        });
        groupReservations = [...groupReservations, ...res.data.data];
      });
    }
    const addStartAndEndTime = await asyncMap(reservations, async (reservation: ReservationData) => {
      return {
        ...reservation,
        start: moment(reservation.datetime).format(),
        end: moment(reservation.datetime).add(this.selectedCourse?.duration_minute, "minute").format(),
        course: this.selectedCourse,
      };
    });

    this.reservations = addStartAndEndTime;
    this.groupReservations = await asyncMap(groupReservations, async (reservation: ReservationData) => {
      return {
        ...reservation,
        start: moment(reservation.datetime).format(),
        end: moment(reservation.datetime).add(this.selectedCourse?.duration_minute, "minute").format(),
        course: this.courses.find((course) => course.id === reservation.course_id),
      };
    });
    CalendarModule.weeklyAdd([]);
    if (this.selectedInitialView === "timeGridWeek") {
      this.createWeeklyStock();
    }
    if (this.selectedInitialView === "dayGridMonth") {
      this.createMonthlyStock();
    }
    this.calendarHeaderActive();
    this.loading = false;
  }

  private prevButton: Element | null = null;
  private nextButton: Element | null = null;
  private dayButton: Element | null = null;
  private weekButton: Element | null = null;
  private monthButton: Element | null = null;

  private calendarHeaderDisabled() {
    this.prevButton?.setAttribute("disabled", "true");
    this.nextButton?.setAttribute("disabled", "true");
    this.dayButton?.setAttribute("disabled", "true");
    this.weekButton?.setAttribute("disabled", "true");
    this.monthButton?.setAttribute("disabled", "true");
  }
  private calendarHeaderActive() {
    this.prevButton?.removeAttribute("disabled");
    this.nextButton?.removeAttribute("disabled");
    this.dayButton?.removeAttribute("disabled");
    this.weekButton?.removeAttribute("disabled");
    this.monthButton?.removeAttribute("disabled");
  }

  private initializeCalendar() {
    // カレンダーの初期ビューに基づいて高さを設定
    this.setCalendarHeight(this.selectedInitialView);

    // カレンダーオプションを更新
    this.$nextTick(() => {
      if (this.fullCalendar) {
        this.fullCalendar.getApi().setOption("height", this.calendarOptions.height);
      }
    });
  }

  private async mounted() {
    this.loading = true;
    await this.getCourses();
    this.showChildComponent = true;

    // カレンダーの初期設定を行う
    this.initializeCalendar();
    if (this.fullCalendar.getApi().currentData.viewApi.type === "timeGridDay") {
      const el = document.getElementsByClassName("fc-view-harness");
      (el[0] as HTMLElement).style.display = "none";
    }
    if (this.selectedCourse) {
      this.prevButton = document.getElementsByClassName("fc-prev-button")[0];
      this.nextButton = document.getElementsByClassName("fc-next-button")[0];
      this.dayButton = document.getElementsByClassName("fc-timeGridDay-button")[0];
      this.weekButton = document.getElementsByClassName("fc-timeGridWeek-button")[0];
      this.monthButton = document.getElementsByClassName("fc-dayGridMonth-button")[0];
      if (this.selectedCourse?.id === "all") {
        this.weekButton?.setAttribute("disabled", "true");
        this.monthButton?.setAttribute("disabled", "true");
        this.dayButton?.setAttribute("disabled", "true");
      }
      this.dayButton.addEventListener("click", () => {
        CalendarModule.changeSelectedInitialView("timeGridDay");
        this.getReservations();
        location.reload();
      });
      this.weekButton.addEventListener("click", () => {
        CalendarModule.changeSelectedInitialView("timeGridWeek");
        this.getReservations();
        location.reload();
      });
      this.monthButton.addEventListener("click", () => {
        CalendarModule.changeSelectedInitialView("dayGridMonth");
        this.getReservations();
        location.reload();
      });
      if (this.selectedCourse?.id !== "all") {
        const res = await FacilityService.getCourseDetail(this.hospitalId, this.selectedCourse.id);
        CalendarModule.changeSelectedCourse(res.data.data[0]);
        if (
          this.fullCalendar.getApi().currentData.viewApi.type === "timeGridDay" &&
          checkHoliday(this.fullCalendar.getApi().currentData.viewApi.currentStart)
        ) {
          this.businessHoursArrayFactoryByHoliday(); // 祝日の営業時間設定
        } else {
          this.businessHoursArrayFactory(); // 営業時間設定
        }
      }
      this.prevButton.addEventListener("click", () => {
        if (this.selectedCourse?.id !== "all") {
          this.getReservations();
        }
        if (
          this.fullCalendar.getApi().currentData.viewApi.type === "timeGridDay" ||
          this.fullCalendar.getApi().currentData.viewApi.type === "timeGridWeek"
        ) {
          CalendarModule.changeCurrentDate(
            moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format("YYYY-MM-DD")
          );
          if (this.selectedCourse?.id !== "all") {
            this.setDayViewBusinessHour(); // slotMinTime,slotMaxTimeの設定
            if (checkHoliday(this.fullCalendar.getApi().currentData.viewApi.currentStart)) {
              this.businessHoursArrayFactoryByHoliday(); // 祝日の営業時間設定
            } else {
              this.businessHoursArrayFactory(); // 営業時間設定
            }
          }
        }
      });
      this.nextButton.addEventListener("click", () => {
        if (this.selectedCourse?.id !== "all") {
          this.getReservations();
        }
        if (
          this.fullCalendar.getApi().currentData.viewApi.type === "timeGridDay" ||
          this.fullCalendar.getApi().currentData.viewApi.type === "timeGridWeek"
        ) {
          CalendarModule.changeCurrentDate(
            moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format("YYYY-MM-DD")
          );
          if (this.selectedCourse?.id !== "all") {
            this.setDayViewBusinessHour(); // slotMinTime,slotMaxTimeの設定
            if (checkHoliday(this.fullCalendar.getApi().currentData.viewApi.currentStart)) {
              this.businessHoursArrayFactoryByHoliday(); // 祝日の営業時間設定
            } else {
              this.businessHoursArrayFactory(); // 営業時間設定
            }
          }
        }
      });
      if (this.selectedCourse && this.selectedCourse.id !== "all") {
        if (this.selectedInitialView !== "timeGridDay") {
          await this.setDayViewBusinessHour();
          await this.getReservations();
        }
      }
    }
    this.loading = false;
  }

  private deleteReservation() {
    CalendarModule.deleteReservation();
    this.closeModal("delete");
    this.closeModal("reservation-edit");
  }

  private openDeleteModal() {
    this.closeModal("reservation-edit");
    this.showModal("delete");
  }

  private changeReservationMemo() {
    CalendarModule.changeReservationMemo(this.selectedReservation);
    this.closeModal("reservation-edit");
  }

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private getBusinessHoursInMinutes(
    businessHours: { daysOfWeek: number[]; startTime: string; endTime: string }[]
  ): number {
    // 一番早いstartTimeを取得
    const earliestStartTime = businessHours.reduce((earliest, current) => {
      return current.startTime < earliest ? current.startTime : earliest;
    }, businessHours[0].startTime);

    // 一番遅いendTimeを取得
    const latestEndTime = businessHours.reduce((latest, current) => {
      return current.endTime > latest ? current.endTime : latest;
    }, businessHours[0].endTime);

    // startTimeとendTimeを分に変換
    const [startHour, startMinute] = earliestStartTime.split(":").map(Number);
    const [endHour, endMinute] = latestEndTime.split(":").map(Number);

    // 営業時間を分で計算
    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;

    return endTimeInMinutes - startTimeInMinutes;
  }

  private convertTimeToMinutes(time: string): number {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 60 + minutes + Math.floor(seconds / 60);
  }

  private displayReservationInfo(item: any) {
    const name = item.customer_last_name ? item.customer_last_name : item.member_info.last_name;
    let petName = item.pet_name ? item.pet_name : "";
    if (!petName) petName = item.patient_name ? item.patient_name : "";
    const nameKana = item.customer_last_name ? item.customer_last_name_kana : item.member_info.last_name_kana;
    let petNameKana = item.pet_name ? item.pet_name_kana : "";
    if (!petNameKana) petNameKana = item.patient_name ? item.patient_name_kana : "";
    let petType = item.pet_animal_type ? item.pet_animal_type : "";
    if (!petType) petType = item.patient_animal_type ? item.patient_animal_type : "";
    return (
      `
      ${item.patient && item.patient.alias_id ? item.patient.alias_id : ""}` +
      "　" +
      `${name ? name : `(${nameKana})`}` +
      "　" +
      "様　" +
      `${petName ? petName : `(${petNameKana})`}` +
      "　" +
      `${petType}`
    );
  }

  private setDayViewBusinessHour() {
    const startTimes: Moment[] = [];
    const endTimes: Moment[] = [];
    const daysOfWeek = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).weekday();
    let stringDaysOfWeek = "";
    if (daysOfWeek === 0) {
      stringDaysOfWeek = "Sun";
    } else if (daysOfWeek === 1) {
      stringDaysOfWeek = "Mon";
    } else if (daysOfWeek === 2) {
      stringDaysOfWeek = "Tue";
    } else if (daysOfWeek === 3) {
      stringDaysOfWeek = "Wed";
    } else if (daysOfWeek === 4) {
      stringDaysOfWeek = "Thu";
    } else if (daysOfWeek === 5) {
      stringDaysOfWeek = "Fri";
    } else if (daysOfWeek === 6) {
      stringDaysOfWeek = "Sat";
    }
    if (checkHoliday(this.fullCalendar.getApi().currentData.viewApi.currentStart)) stringDaysOfWeek = "Holiday";
    this.selectedCourse?.details.forEach((detail: CourseDetail) => {
      detail.days.forEach((day) => {
        if (this.selectedInitialView === "timeGridDay") {
          if (day.available_days.includes(stringDaysOfWeek)) {
            day.times.forEach((time) => {
              startTimes.push(
                moment().set("hour", time.on_time.start_hour).set("minute", time.on_time.start_minute).set("second", 0)
              );
              endTimes.push(
                moment().set("hour", time.on_time.end_hour).set("minute", time.on_time.end_minute).set("second", 0)
              );
            });
          }
        } else if (this.selectedInitialView === "timeGridWeek") {
          day.times.forEach((time) => {
            startTimes.push(
              moment().set("hour", time.on_time.start_hour).set("minute", time.on_time.start_minute).set("second", 0)
            );
            endTimes.push(
              moment().set("hour", time.on_time.end_hour).set("minute", time.on_time.end_minute).set("second", 0)
            );
          });
        }
      });
    });

    startTimes.sort((a, b) => moment(a).diff(b));
    endTimes.sort((a, b) => moment(b).diff(a));
    if (moment(startTimes[0]).format("HH:mm") != "00:00" && moment(endTimes[0]).format("HH") != "00") {
      this.calendarOptions.views.timeGridWeek = {
        slotMinTime: moment(startTimes[0]).format("HH:mm"),
        slotMaxTime: moment(endTimes[0]).format("HH:mm"),
      };
      this.calendarOptions.views.timeGridDay = {
        slotMinTime: moment(startTimes[0]).format("HH:mm"),
        slotMaxTime: moment(endTimes[0]).format("HH:mm"),
      };
    } else if (moment(startTimes[0]).format("HH:mm") != "00:00" && moment(endTimes[0]).format("HH") == "00") {
      this.calendarOptions.views.timeGridWeek = {
        slotMinTime: moment(startTimes[0]).format("HH:mm"),
      };
      this.calendarOptions.views.timeGridDay = {
        slotMinTime: moment(startTimes[0]).format("HH:mm"),
      };
    }
  }

  private start = ""; //現在表示している日時のスタート
  private end = ""; //現在表示している日時のエンド
  // カレンダーの設定
  private calendarOptions: any = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: this.selectedInitialView,
    headerToolbar: {
      center: "prev,title,next",
      right: "timeGridDay,timeGridWeek,dayGridMonth",
      left: "",
    },
    locale: "ja",
    firstDay: this.selectedInitialView === "timeGridWeek" ? new Date().getDay() : 1,
    events: [],
    eventBackgroundColor: "#fff",
    eventBorderColor: "#fff",

    views: {
      // 週表示で表示する時間帯
      timeGridWeek: {
        slotMinTime: "00:00",
        slotMaxTime: "24:00",
      },
      timeGridDay: {
        slotMinTime: "00:00:00",
        slotMaxTime: "24:00:00",
      },
    },
    nowIndicator: true,
    // viewが変わった時に動くイベント
    datesSet: this.handleDatesSet,
    navLinkDayClick: this.clickMonthDay,
    dayCellClassNames: this.dayCellClassNames,
    initialDate: this.currentDate ? this.currentDate : moment(new Date()).format("YYYY-MM-DD"),
    expandRows: true,
    allDaySlot: false,
    navLinks: true,
    slotLabelFormat: {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: false,
      meridiem: "short",
    },
    buttonText: {
      day: "日",
      month: "月",
      week: "週",
      prev: "◀︎",
      next: "▶︎",
    },
    slotDuration: `00:${this.selectedCourse?.duration_minute}:00`,
    slotLabelInterval: this.selectedCourse?.duration_minute == 90 ? "01:30" : "01:00",
    businessHours: this.businessHours,
  };

  private getLabelColor(label: string) {
    if (label === "一般診療") {
      return "rgba(85, 171, 53, 0.6)";
    } else if (label === "予防接種") {
      return "rgba(53, 62, 171, 0.6)";
    }
  }

  private convertDays(days: string[]) {
    const convertedDays = days.map((value) => {
      if (value === "Sun") {
        return 0;
      } else if (value === "Mon") {
        return 1;
      } else if (value === "Tue") {
        return 2;
      } else if (value === "Wed") {
        return 3;
      } else if (value === "Thu") {
        return 4;
      } else if (value === "Fri") {
        return 5;
      } else if (value === "Sat") {
        return 6;
      } else {
        return 99;
      }
    });
    return convertedDays;
  }

  private businessHourStringFactory(hour: number, minute: number, duration?: number) {
    let hours = moment().set("hour", hour).set("minute", minute).format("HH:mm");
    if (duration && hour === 0 && minute === 0) {
      hours = moment().set("hour", hour).set("minute", minute).add(-1, "minute").format("HH:mm");
    } else if (duration) {
      hours = moment().set("hour", hour).set("minute", minute).add(duration, "minute").format("HH:mm");
      if (hours.slice(0, 2) === "00" && hours.slice(3, 5) === "00") {
        hours = "23:59";
      }
    }
    return hours;
  }

  private businessHoursArrayFactory() {
    const businessHoursArray: BusinessHours[] = [];
    this.selectedCourse?.details.forEach((detail) => {
      detail.days?.forEach((courseDay: CourseDay) => {
        // 曜日のリストをstring→numberへ
        const daysOfWeek = this.convertDays(courseDay.available_days);
        const holidayArray: any[] = [];
        // 祝日営業する場合は祝日営業時間を優先させるため他の時間から同じ曜日を除外する
        const start = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format("YYYY-MM-DD");
        const end = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd).format("YYYY-MM-DD");
        const dateArray: string[] = [];
        const currentDate = moment(start);
        while (currentDate < moment(end)) {
          dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
          currentDate.add(1, "days");
        }
        // dateArrayの中から祝日の日の曜日を取得する
        dateArray.forEach((date) => {
          if (checkHoliday(new Date(date))) {
            holidayArray.push(moment(date).day());
          }
        });

        courseDay.times.forEach((time) => {
          const startTime = this.businessHourStringFactory(time.on_time.start_hour, time.on_time.start_minute);
          let endTime = this.businessHourStringFactory(time.on_time.end_hour, time.on_time.end_minute);
          if (time.on_time.end_hour == 24) {
            endTime = "24:00";
          }
          const businessHours = {
            daysOfWeek: daysOfWeek.includes(99)
              ? courseDay.is_holiday_primary
                ? daysOfWeek.concat(holidayArray)
                : daysOfWeek
              : courseDay.is_holiday_primary
              ? daysOfWeek.filter((day) => !holidayArray.includes(day))
              : daysOfWeek,
            startTime,
            endTime,
          };

          // detail.periodの範囲内の日付を取得
          const periodStart = moment([
            detail.period.start_year,
            detail.period.start_month - 1,
            detail.period.start_date,
          ]);
          const isEndDateSet = detail.period.end_year && detail.period.end_month && detail.period.end_date;
          const periodEnd = isEndDateSet
            ? moment([detail.period.end_year!, detail.period.end_month! - 1, detail.period.end_date!])
            : "";
          const calendarStart = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart);
          const calendarEnd = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd);
          // カレンダーの表示範囲とdetail.periodの範囲を比較
          const validStart = moment.max(periodStart, calendarStart);
          const validEnd = isEndDateSet ? moment.min(periodEnd as Moment, calendarEnd) : calendarEnd;
          // その範囲内にある日付の曜日を取得
          const validDaysOfWeek: number[] = [];
          const isCalendarEnd = validEnd.isSame(calendarEnd);
          if (isCalendarEnd) {
            for (let m = moment(validStart); m.isBefore(validEnd); m.add(1, "days")) {
              validDaysOfWeek.push(m.day());
            }
          } else {
            for (let m = moment(validStart); m.isSameOrBefore(validEnd); m.add(1, "days")) {
              validDaysOfWeek.push(m.day());
            }
          }

          // 取得した曜日をdaysOfWeekから削除
          businessHours.daysOfWeek = businessHours.daysOfWeek.filter(
            (day) => validDaysOfWeek.includes(day) || day === 99
          );

          businessHoursArray.push(_.cloneDeep(businessHours));
        });
      });
    });
    CalendarModule.changeBusinessHours(_.cloneDeep(businessHoursArray));
    if (this.currentDate && !this.isReloaded) {
      location.reload();
      CalendarModule.changeIsReloaded(true);
    } else {
      CalendarModule.changeIsReloaded(false);
    }
    // console.log("businessHoursArray", businessHoursArray);
  }

  private businessHoursArrayFactoryByHoliday() {
    const businessHoursArray: BusinessHours[] = [];
    let daysOfWeek: number[] = [];
    daysOfWeek.push(moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).weekday());
    this.selectedCourse?.details.forEach((detail) => {
      detail.days?.forEach((courseDay: CourseDay) => {
        // 曜日のリストをstring→numberへ
        if (courseDay.available_days.includes("Holiday")) {
          courseDay.times.forEach((time) => {
            const startTime = this.businessHourStringFactory(time.on_time.start_hour, time.on_time.start_minute);
            let endTime = this.businessHourStringFactory(
              time.on_time.end_hour,
              time.on_time.end_minute,
              this.selectedCourse?.duration_minute
            );
            if (time.on_time.end_hour == 24) {
              endTime = "24:00";
            }
            businessHoursArray.push({
              daysOfWeek,
              startTime,
              endTime,
            });
          });
        }
      });
    });
    CalendarModule.changeBusinessHours(businessHoursArray);
    if (this.currentDate && !this.isReloaded) {
      location.reload();
      CalendarModule.changeIsReloaded(true);
    } else {
      CalendarModule.changeIsReloaded(false);
    }
  }

  private editDailyEvent(time: Date) {
    CalendarModule.selectedDailyEvent(time);
    // const selectEvent = this.dayEvents.find((event) => event.start === moment(this.selectedTargetTime).format());
    // if (selectEvent) this.selectedReservation = selectEvent;
    this.showModal(`reservation-edit`);
  }

  private dayCellClassNames(data: any) {
    if (checkHoliday(data.date)) {
      return ["fc-holiday"];
    }
  }

  private weeklyVisibleRange(currentDate: any) {
    return { start: "2022-12-4", end: "2022-12-10" };
  }

  private setCalendarHeight(viewType: string) {
    if (viewType === "dayGridMonth") {
      this.calendarOptions.height = "1000px";
      this.calendarOptions.contentHeight = "auto";
    } else if (viewType === "timeGridWeek") {
      const calendarHeight =
        (this.getBusinessHoursInMinutes(this.businessHours) /
          this.convertTimeToMinutes(this.calendarOptions.slotDuration)) *
        this.weeklyHeight;
      this.calendarOptions.height = calendarHeight + 300 + "px";
    } else if (viewType === "timeGridDay") {
      this.calendarOptions.contentHeight = "auto";
      if (this.selectedCourse && this.selectedCourse.duration_minute < 60) {
        this.calendarOptions.height = "1500px";
      } else {
        this.calendarOptions.height = "1000px";
      }
      CalendarModule.changeCurrent("day");
      this.calendarOptions.events = this.currentEvents;
    }
  }

  private handleDatesSet(info: any) {
    // カレンダーから現在表示している日時のスタートを取得
    this.start = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format();
    // カレンダーから現在表示している日時のエンドを取得
    this.end = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd).format();
    if (this.selectedCourse) {
      this.setCalendarHeight(info.view.type);
      this.$nextTick(() => {
        if (this.fullCalendar) {
          this.fullCalendar.getApi().setOption("height", this.calendarOptions.height);
        }
      });
      const nonBusinessArea = document.querySelector(".fc-non-business");
      if (nonBusinessArea) nonBusinessArea.textContent = "診療時間外";
    }
  }

  private async clickMonthDay(info: Date) {
    CalendarModule.changeSelectedInitialView("timeGridDay");
    CalendarModule.changeCurrentDate(moment(info).format("YYYY-MM-DD"));
    location.reload();
  }

  private dateCheckToday(date: string) {
    const argDate = moment(date).format("YYYYMMDD");
    const start = moment(this.start).format("YYYYMMDD");
    return moment(argDate).isSame(start);
  }
  private dateCheckWeeklyOrMonthly(date: string) {
    const argDate = moment(date).format("YYYYMMDD");
    const start = moment(this.start).format("YYYYMMDD");
    const end = moment(this.end).format("YYYYMMDD");
    return moment(argDate).isSameOrAfter(start) && moment(argDate).isBefore(end);
  }

  private displayReservationInfoName(item: any) {
    if (!item.customer && !item.member_info) {
      return "";
    }
    const name = item.customer_last_name ? item.customer_last_name : item.member_info.last_name;
    const nameKana = item.customer_last_name ? item.customer_last_name_kana : item.member_info.last_name_kana;
    return `${name ? name : `(${nameKana})`}`;
  }
  private displayReservationInfoPetName(item: any) {
    let petName = item.pet_name ? item.pet_name : "";
    if (!petName) petName = item.patient_name ? item.patient_name : "";
    let petNameKana = item.pet_name ? item.pet_name_kana : "";
    if (!petNameKana) petNameKana = item.patient_name ? item.patient_name_kana : "";
    return `${petName ? petName : `(${petNameKana})`}`;
  }

  private async getReservationCsvData() {
    this.csvDownloading = true;
    // 子コンポーネントのメソッドを呼び出す
    if (this.$refs.allCourseCalendar) {
      await (this.$refs.allCourseCalendar as any).createOutputCsvReservations();
    }
    const config = {
      delimiter: ",", // 区切り文字
      header: true, // キーをヘッダーとして扱う
      newline: "\r\n", // 改行
    };
    let calendarView = "";
    if (this.dayButton?.classList.contains("fc-button-active")) {
      calendarView = "date";
    } else {
      calendarView = "weekly";
    }
    const jsonDataList: any = [];
    let reservations = [...this.reservations, ...this.groupReservations];
    if (
      this.selectedCourse!.id === "all" ||
      (this.selectedCourse?.id !== "all" && this.selectedInitialView === "timeGridDay")
    ) {
      reservations = this.allCourseReservations;
    }
    reservations.forEach((reservation: any) => {
      if (
        (calendarView == "date" && this.dateCheckToday(reservation.datetime)) ||
        (calendarView == "weekly" && this.dateCheckWeeklyOrMonthly(reservation.datetime))
      ) {
        const jsonData = {
          予約ID: reservation.id,
          日時: reservation.datetime,
          コース名: reservation.course?.name ?? "",
          飼い主名: this.displayReservationInfoName(reservation),
          どうぶつ名: this.displayReservationInfoPetName(reservation),
          来院目的: reservation.message,
        };
        // CSVデータ取得
        jsonDataList.push(jsonData);
      }
    });
    // 区切り文字へ変換
    const delimiterString = Papa.unparse(jsonDataList, config);

    // blodへの変換
    const strArray = Encoding.stringToCode(delimiterString);
    const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const UintArray = new Uint8Array(convertedArray);
    const blob = new Blob([UintArray], { type: "text/csv" });

    // download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `予約リスト.csv`;
    link.click();
    this.csvDownloading = false;
  }

  private async changeAvailableCount(start: string, stock: number, status: string) {
    if (!this.canUpdateCourse) return;
    this.loadingUpdateCourse = true;
    const specialTime = {
      year: 0,
      month: 0,
      date: 0,
      start_hour: 0,
      start_minute: 0,
      available_count: 0,
    };
    if (this.hospitalId && this.selectedCourse) {
      const currentCourse = _.cloneDeep(this.selectedCourse);
      specialTime.year = moment(start).get("year");
      specialTime.month = moment(start).get("month") + 1;
      specialTime.date = moment(start).get("date");
      specialTime.start_hour = moment(start).get("hour");
      specialTime.start_minute = moment(start).get("minute");
      // 対象のdetailのIndexを取得してそれに対して処理を行う
      let detailIndex = 0;
      if (currentCourse.details.length > 1) {
        // startの日付がdetailの期間内になっているdetailのindexを取得
        detailIndex = currentCourse.details.findIndex((detail) => {
          let endDate = null;
          if (detail.period.end_year && detail.period.end_month && detail.period.end_date) {
            endDate = moment()
              .set("year", detail.period.end_year)
              .set("month", detail.period.end_month - 1)
              .set("date", detail.period.end_date)
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59);
          }
          const startDate = moment()
            .set("year", detail.period.start_year)
            .set("month", detail.period.start_month - 1)
            .set("date", detail.period.start_date)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0);
          return endDate
            ? moment(start).isBetween(startDate, endDate, null, "[]")
            : moment(start).isSameOrAfter(startDate);
        });
      }
      detailIndex = detailIndex === -1 ? 0 : detailIndex;
      if (currentCourse.details[detailIndex].special_times?.length) {
        const sameSpecialTimeIndex = currentCourse.details[detailIndex].special_times?.findIndex(
          (item) =>
            item.year === specialTime.year &&
            item.month === specialTime.month &&
            item.date === specialTime.date &&
            item.start_hour === specialTime.start_hour &&
            item.start_minute === specialTime.start_minute
        ) as number;
        if (sameSpecialTimeIndex !== -1) {
          // 既に同じ日時の設定がある時
          if (status === "add") {
            (currentCourse.details[detailIndex].special_times as CourseSpecialTime[])[
              sameSpecialTimeIndex
            ].available_count = stock + 1;
          } else {
            (currentCourse.details[detailIndex].special_times as CourseSpecialTime[])[
              sameSpecialTimeIndex
            ].available_count = stock - 1;
          }
        } else {
          // 同じ日時の設定ない時
          if (status === "add") {
            specialTime.available_count = stock + 1;
          } else {
            specialTime.available_count = stock - 1;
          }
          currentCourse.details[detailIndex].special_times?.push(specialTime);
        }
      } else {
        if (status === "add") {
          specialTime.available_count = stock + 1;
        } else {
          specialTime.available_count = stock - 1;
        }
        currentCourse.details[detailIndex].special_times = [specialTime];
      }
      const payload = deleteNullProp(currentCourse);
      const res = await FacilityService.updateCourse(this.hospitalId, this.selectedCourse.id, payload);
      CalendarModule.changeSelectedCourse(res.data.data[0]);
      this.createWeeklyStock();
      this.loadingUpdateCourse = false;
    }
  }

  private async getPossibleReservations() {
    try {
      const calendarStartDate = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart);
      const calendarEndDate = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd);
      const startYear = calendarStartDate.get("year").toString();
      const startMonth = String(calendarStartDate.get("month") + 1);
      const endYear = calendarEndDate.get("year").toString();
      const endMonth = String(calendarEndDate.get("month") + 1);
      if (this.selectedCourse && this.selectedCourse.id) {
        const res = await FacilityService.getPossibleReservations(
          this.hospitalId,
          this.selectedCourse.id,
          startYear,
          startMonth,
          "True"
        );
        this.possibleReservations = res.data.data;
        if (endMonth !== startMonth) {
          const res = await FacilityService.getPossibleReservations(
            this.hospitalId,
            this.selectedCourse.id,
            endYear,
            endMonth,
            "True"
          );
          this.possibleReservations = [...this.possibleReservations, ...res.data.data];
        }
      }
      // this.loading = false;
    } catch (error: any) {
      // this.loading = false;
      throw new Error(error);
    }
  }

  private weeklyFactory(businessHours: BusinessHours[]) {
    const weekly: WeeklyEvent[] = [];
    businessHours.forEach((businessHour) => {
      // 予約受付枠のスタート日時定義
      let weeklyStart = moment(this.start)
        .hour(Number(_.cloneDeep(businessHour).startTime.slice(0, 2)))
        .minute(Number(_.cloneDeep(businessHour).startTime.slice(3, 5)))
        .format();
      // 同じ曜日設定の時間帯リスト
      const dayTimes = _.cloneDeep(businessHours).filter(
        (value) => (_.cloneDeep(businessHour).daysOfWeek = value.daysOfWeek)
      );
      dayTimes.sort((a: BusinessHours, b: BusinessHours) => {
        if (a.endTime.slice(0, 2) > b.endTime.slice(0, 2)) {
          return 1;
        } else {
          return -1;
        }
      });
      // 表示しているカレンダーのスタート日時からエンド日時までループ
      for (let i = 1; moment(weeklyStart).isBefore(moment(this.end)); i++) {
        const day = moment(weeklyStart).day();
        const targetDayTimes = dayTimes.filter((value) => value.daysOfWeek.includes(day));
        // 休憩開始時間
        if (targetDayTimes.length == 0) {
          weeklyStart = moment(weeklyStart)
            .add(1, "days")
            .hour(Number(_.cloneDeep(businessHour).startTime.slice(0, 2)))
            .minute(Number(_.cloneDeep(businessHour).startTime.slice(3, 5)))
            .format();
          continue;
        }
        const startRestTime = moment(weeklyStart)
          .hour(Number(targetDayTimes[0].endTime.slice(0, 2)))
          .minute(Number(targetDayTimes[0].endTime.slice(3, 5)))
          .format();
        // 休憩終了時間 同じ曜日の時間設定が2つ以上あれば最後のスタート時間、なければ最初の終わりの時間
        const endRestTime = moment(weeklyStart)
          .hour(
            Number(
              targetDayTimes[1]
                ? targetDayTimes[targetDayTimes.length - 1].startTime.slice(0, 2)
                : targetDayTimes[0].endTime.slice(0, 2)
            )
          )
          .minute(
            Number(
              targetDayTimes[1]
                ? targetDayTimes[targetDayTimes.length - 1].startTime.slice(3, 5)
                : targetDayTimes[0].endTime.slice(3, 5)
            )
          )
          .format();
        // 仕事開始時間
        const startTime = moment(weeklyStart)
          .hour(Number(targetDayTimes[0].startTime.slice(0, 2)))
          .minute(Number(targetDayTimes[0].startTime.slice(3, 5)))
          .format();
        // 仕事終了時間
        const endTime = moment(weeklyStart)
          .hour(Number(targetDayTimes[targetDayTimes.length - 1].endTime.slice(0, 2)))
          .minute(Number(targetDayTimes[targetDayTimes.length - 1].endTime.slice(3, 5)))
          .format();
        // 予約数
        const event: WeeklyEvent = {
          title: 0,
          stock: this.selectedCourse ? this.selectedCourse.available_count : 0,
          groupReservedCount: 0,
          start: weeklyStart,
          end: moment(weeklyStart)
            .add(this.selectedCourse!.duration_minute / 60, "hours")
            .format(),
        };
        // if (!_.cloneDeep(businessHour).daysOfWeek.includes(99) && checkHoliday(new Date(event.start))) {
        //   event.stock = 0;
        // }

        const duplicateEvent = weekly.find((value) => moment(value.start).isSame(event.start));
        if (!duplicateEvent) {
          //specialTimeがあったら上書き
          if (this.selectedCourse?.details) {
            for (const detail of this.selectedCourse.details) {
              if (detail.special_times) {
                const findSpecialTime = detail.special_times.find((item) => {
                  return (
                    moment()
                      .set("year", item.year)
                      .set("month", item.month - 1)
                      .set("date", item.date)
                      .set("hour", item.start_hour)
                      .set("minute", item.start_minute)
                      .set("second", 0)
                      .format() === weeklyStart
                  );
                });
                if (findSpecialTime) {
                  event.stock = findSpecialTime.available_count;
                  break; // 最初に見つかった特別時間を適用したら、ループを抜ける
                }
              }
            }
          }
          if (
            !moment(event.start).isBetween(startRestTime, endRestTime, null, "[)") &&
            moment(event.start).isBetween(
              startTime,
              moment(endTime).add(-this.selectedCourse!.duration_minute, "minutes"),
              null,
              "[]"
            ) &&
            _.cloneDeep(businessHour).daysOfWeek.includes(moment(event.start).get("day"))
          ) {
            if (this.possibleReservations.length) {
              const possibleReservation = this.possibleReservations.find((reservation: any) => {
                const reservationDate = moment(reservation.datetime).format();
                // return moment(reservationDate).isBetween(event.start, event.end, null, "()");
                return moment(reservationDate).isSame(event.start);
              });
              if (possibleReservation) {
                event.title = possibleReservation.available_count - possibleReservation.remained_count;
              }
            }
            weekly.push(event);
          }
        }

        if (event.start >= endTime) {
          weeklyStart = moment(weeklyStart)
            .add(1, "days")
            .hour(Number(_.cloneDeep(businessHour).startTime.slice(0, 2)))
            .minute(Number(_.cloneDeep(businessHour).startTime.slice(3, 5)))
            .format();
          if (day == 5) {
          }
        } else if (moment(event.end).isBetween(startRestTime, endRestTime, null, "[]")) {
          weeklyStart = endRestTime;
        } else {
          weeklyStart = event.end;
        }
      }
    });
    return weekly;
  }

  private createWeeklyStock() {
    // カレンダーから現在表示している日時のスタートを取得
    this.start = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format();
    // カレンダーから現在表示している日時のエンドを取得
    this.end = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd).format();
    let weekly = [];
    weekly = this.weeklyFactory(this.businessHours);
    CalendarModule.weeklyAdd(weekly);
    CalendarModule.changeCurrent("weekly");

    this.calendarOptions.events = this.currentEvents;
  }

  private async createMonthlyStock() {
    // CalendarModule.monthlyReset();
    let monthly = [];
    // カレンダーから現在表示している日時のスタートを取得
    this.start = moment(this.fullCalendar.getApi().currentData.viewApi.currentStart).format();
    // カレンダーから現在表示している日時のエンドを取得
    this.end = moment(this.fullCalendar.getApi().currentData.viewApi.currentEnd).format();
    const year = moment(this.start).get("year").toString();
    const month = String(moment(this.start).get("month") + 1);
    // 表示しているカレンダーのイベントがあるかどうかチェック
    for (let i = 1; this.end !== this.start; i++) {
      let defaultEvent: MonthlyEvent = {
        title: 0,
        stock: 0,
        start: "",
        groupReservedCount: 0,
      };
      defaultEvent.start = moment(this.start).format("YYYY-MM-DD");
      monthly.push(defaultEvent);
      this.start = moment(this.start).add(1, "days").format();
    }
    const res = await FacilityService.getPossibleReservations(
      this.hospitalId,
      this.selectedCourse!.id,
      year,
      month,
      "True"
    );
    const possibleReservations = res.data.data;
    for (const v of possibleReservations) {
      const d = moment(v.datetime).get("date");
      monthly[d - 1].title = monthly[d - 1].title + (v.available_count - v.remained_count);
      monthly[d - 1].stock = monthly[d - 1].stock + v.available_count;
    }
    CalendarModule.monthlyAdd(monthly);
    CalendarModule.changeCurrent("monthly");
    this.calendarOptions.events = this.currentEvents;
  }
}
